import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.scss";
import { useAuth } from "./utils/AuthContext";
import { useOrgContext } from "./utils/OrgContext";
import FormControl from "@mui/material/FormControl";
import {
  CircularProgress,
  Select,
  MenuItem,
  Button,
  Icon,
} from "@mui/material"; // <-- MUI imports
import mainLogo from "../assets/devlo.png";
function Sidebar({ showTab = "repos" }) {
  const [isOpen, setIsOpen] = useState(true); // State to manage the sidebar visibility
  const toggleSidebar = () => setIsOpen(!isOpen); // Function to toggle the sidebar
  const { authUser, authLoading, signOut } = useAuth();
  // OrgContext items (adjust the path/name as needed)
  const { orgs, currentOrg, selectOrgId, loadingOrgs } = useOrgContext();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  // If user chooses "Add/Modify" in the dropdown:
  const openAddModifyLink = () => {
    window.open(
      "https://github.com/apps/devloai/installations/select_target",
      "_blank",
      "noopener,noreferrer"
    );
  };

  // Handler for MUI Select change
  const handleOrgSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "addModify") {
      // Open your "Add/Modify" link
      window.open(
        "https://github.com/apps/devloai/installations/select_target",
        "_blank",
        "noopener,noreferrer"
      );
      return;
    }

    selectOrgId(selectedValue);
  };
  // Custom IconComponent to make the caret (expand_more) white
  const WhiteCaretIcon = (props) => (
    <Icon {...props} style={{ color: "white" }}>
      expand_more
    </Icon>
  );

  return (
    <>
      <button
        className="sidebar-toggle"
        style={{ visibility: isOpen ? "visible" : "hidden" }}
        onClick={toggleSidebar}
      >
        <i className="material-icons">menu</i>{" "}
        {/* This icon represents the hamburger menu */}
      </button>
      <div className={`sidebar ${isOpen ? "" : "collapsed"}`}>
        <div className="f-col cntnt">
          <div className="f-col upper">
            <h3 className="f-row aic jcsb">
              <Link className="navbar-brand" to="/">
                <img src={mainLogo} alt="Logo" className="logo" />
                devlo
              </Link>
              <i
                className="material-icons in-sidebar-toggle"
                style={{ visibility: isOpen ? "hidden" : "visible" }}
                onClick={toggleSidebar}
              >
                chevron_left
              </i>
            </h3>
            <div className="org-selector" style={{ marginTop: 16 }}>
              {/* Inline label & icon outside the dropdown */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 7,
                }}
              >
                <Icon style={{ marginRight: 6, fontSize: "14px" }}>
                  corporate_fare
                </Icon>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Organization
                </span>
              </div>
              {orgs.length === 0 || loadingOrgs ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loadingOrgs}
                  startIcon={loadingOrgs ? null : <Icon>add</Icon>}
                  onClick={openAddModifyLink}
                  style={{
                    textTransform: "none",
                    marginTop: 8,
                    backgroundColor: "unset",
                    border: "1px solid white",
                  }}
                >
                  {loadingOrgs ? (
                    <span className="frcc" style={{ color: "white" }}>
                      <CircularProgress
                        style={{
                          color: "white",
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      Loading
                    </span>
                  ) : (
                    "Add organizations"
                  )}
                </Button>
              ) : (
                <FormControl fullWidth size="small">
                  <Select
                    // No label here, since we have a separate label outside
                    value={currentOrg?.id || ""}
                    onChange={handleOrgSelectChange}
                    variant="outlined"
                    // Set the white caret
                    IconComponent={WhiteCaretIcon}
                    // Display how the selected org looks
                    renderValue={(value) => {
                      if (!value) return "Select organization...";
                      const foundOrg = orgs.find((o) => o.id === value);
                      if (!foundOrg) return "Select organization...";
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {foundOrg.org.avatarUrl && (
                            <img
                              src={foundOrg.org.avatarUrl}
                              alt="Org Avatar"
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                                marginRight: 8,
                              }}
                            />
                          )}
                          {foundOrg.org.name}
                        </div>
                      );
                    }}
                    // MUI sx styling to control border, color, etc.
                    sx={{
                      color: "white",
                      ".MuiSvgIcon-root": {
                        fill: "white",
                      },
                    }}
                  >
                    {orgs.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {org.org.avatarUrl && (
                            <img
                              src={org.org.avatarUrl}
                              alt="Org Avatar"
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                                marginRight: 8,
                              }}
                            />
                          )}
                          {org.org.name}
                        </div>
                      </MenuItem>
                    ))}
                    <MenuItem value="addModify">
                      <Icon fontSize="small" style={{ marginRight: 6 }}>
                        add
                      </Icon>
                      Add/Modify organizations
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="fccc tabs">
              {/* Chat tab - visible to all users */}
              <Link
                to="/chat"
                className={
                  showTab === "chat" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">chat</i>
                Chat
              </Link>

              <Link
                to="/repos"
                className={
                  showTab === "repos" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">code</i> Repositories
              </Link>
              <Link
                to="/settings"
                className={
                  showTab === "settings" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">settings</i>
                Settings
              </Link>

              {/* Guide tab - visible to all users */}
              <Link
                to="/guide"
                className={
                  showTab === "guide" ? "f-row aic active" : "f-row aic"
                }
              >
                <i className="material-icons">help_outline</i>
                Guide
              </Link>
            </div>
          </div>
          <div className="f-row aic jcsb bottom">
            <div className="frcc user-sec">
              {!authLoading && authUser && authUser.avatarUrl ? (
                <>
                  <img
                    src={authUser.avatarUrl}
                    alt="User Avatar"
                    className="user-avatar navbar-avatar"
                  />
                  <h4>{authUser.name}</h4>
                </>
              ) : (
                <CircularProgress />
              )}
            </div>

            <button onClick={handleSignOut}>
              <i className="material-icons">logout</i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
