import React, { useState, useEffect } from "react";
import "./UserManagement.scss";
import {
  Typography,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  refreshOrgMembers,
  getOrgSeats,
  batchSeatsUpdate,
} from "./api/dashboardFunctions";
import { fetchGitHubUser } from "./utils/githubApi";
import { useOrgContext } from "./utils/OrgContext";
import SnackbarAlert from "./SnackbarAlert";

function UserManagement({ orgId, userRole }) {
  const { currentOrg, loadingOrgs } = useOrgContext();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState("");
  const [seatError, setSeatError] = useState("");
  const [confirmingChanges, setConfirmingChanges] = useState(false);
  const [userGithubId, setUserGithubId] = useState(null);
  const [username, setUsername] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Local state for batching changes
  const [pendingChanges, setPendingChanges] = useState({}); // Format: { userId: { type: 'add|update|remove', data: {...} } }

  // Fetch members when component mounts or orgId changes
  useEffect(() => {
    if (!orgId) return;

    const fetchMembers = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await getOrgSeats(orgId);
        setMembers(response.seats);
        setUserGithubId(response.userGithubId);
      } catch (err) {
        setError("Failed to load members. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [orgId]);

  const addPendingUser = async () => {
    setSeatError("");
    if (!username.trim()) {
      setSeatError("Username is required!");
      return;
    }

    const lowerUsername = username.trim().toLowerCase();

    // Check if user already exists in changes or current seats
    const userAlreadyPendingAddition = Object.values(pendingChanges).some(
      (change) =>
        change.data.username.toLowerCase() === lowerUsername &&
        change.type === "add"
    );
    const userInSeats = members.some(
      (seat) => (seat.username || "").toLowerCase() === lowerUsername
    );

    if (userAlreadyPendingAddition || userInSeats) {
      setSeatError("User already exists in your pending changes or seats.");
      return;
    }

    try {
      const userDetails = await fetchGitHubUser(username);
      if (!userDetails || !userDetails.id) {
        setSeatError(
          "User not found. Please check the GitHub username and try again."
        );
        return;
      }

      setPendingChanges((prev) => ({
        ...prev,
        [userDetails.id]: {
          type: "add",
          data: {
            userGithubId: userDetails.id,
            username: userDetails.login,
            avatarUrl: userDetails.avatar_url,
            htmlUrl: userDetails.html_url,
            role: "user",
            active: true,
          },
        },
      }));
      setUsername("");
    } catch (err) {
      setSeatError("Failed to fetch GitHub user details. Please try again.");
    }
  };

  const removePendingSeat = (seat) => {
    const seatId = seat.userGithubId.toString();

    setPendingChanges((prev) => {
      const newChanges = { ...prev };

      // If this was a pending addition, just remove it
      if (newChanges[seatId]?.type === "add") {
        delete newChanges[seatId];
      } else {
        // Otherwise mark it for removal
        newChanges[seatId] = {
          type: "remove",
          data: {
            ...seat,
            active: false,
          },
        };
      }

      return newChanges;
    });
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter" && username.trim()) {
      addPendingUser();
    }
  };

  // Handle forced refresh from GitHub
  const handleRefreshFromGitHub = async () => {
    if (!orgId || refreshing) return;

    setRefreshing(true);
    setError("");

    try {
      const response = await refreshOrgMembers(orgId);
      if (response.severity === "success") {
        setMembers(response.updatedSeats);
        setSnackbar({
          open: true,
          message: response.message,
          severity: response.severity,
        });
      } else {
        setSnackbar({
          open: true,
          message: response.message,
          severity: response.severity,
        });
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to refresh members",
        severity: "error",
      });
    } finally {
      setRefreshing(false);
    }
  };

  const handleUserRoleChange = (userId, newRole) => {
    setPendingChanges((prev) => {
      const existing = prev[userId];
      return {
        ...prev,
        [userId]: {
          type: existing?.type || "update",
          data: {
            ...(existing?.data ||
              members.find((s) => s.userGithubId.toString() === userId)),
            role: newRole,
          },
        },
      };
    });
  };

  const confirmChanges = async () => {
    setSeatError("");
    setConfirmingChanges(true);

    try {
      const {
        message,
        severity,
        seats: updatedSeats,
      } = await batchSeatsUpdate(orgId, pendingChanges);

      setMembers(updatedSeats);
      setPendingChanges({});

      setSnackbar({
        open: true,
        message,
        severity,
      });
    } catch (err) {
      // Handle specific error codes from the backend
      switch (err.code) {
        case "unauthenticated":
          setSeatError("You must be logged in to make changes.");
          break;
        case "not-found":
          setSeatError("Organization not found.");
          break;
        case "permission-denied":
          setSeatError("Only the organization admin can modify seats.");
          break;
        case "internal":
          setSeatError(
            "An unexpected error occurred. Please try again later or contact us at help@devlo.ai."
          );
          break;
        default:
          setSeatError(
            "An error occurred. Please try again later or contact us at help@devlo.ai."
          );
      }
    } finally {
      setConfirmingChanges(false);
    }
  };

  const unifiedSeats = [
    ...members.map((seat) => ({
      ...seat,
      pendingChanges: pendingChanges[seat.userGithubId]?.data,
    })),
    ...Object.entries(pendingChanges)
      .filter(([_, change]) => change.type === "add")
      .map(([id, change]) => ({
        ...change.data,
        isPendingAddition: true,
      })),
  ];

  const canRefreshOrgMembers =
    (userRole === "admin" || userRole === "owner") &&
    !loadingOrgs &&
    currentOrg.org.type === "Organization";
  return (
    <div className="user-management">
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : members.length === 0 ? (
        <div className="empty-message">
          No members found. Click the refresh button to load members from
          GitHub.
        </div>
      ) : (
        <>
          <div className="f-row add-users">
            <div className="f-row aic add-user">
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Github username"
                type="text"
                onKeyDown={onEnterPress}
              />
              <button
                className={`save-btn ${!username.trim() ? "disabled" : ""}`}
                onClick={addPendingUser}
                disabled={!username.trim()}
              >
                Add
              </button>
            </div>
            <div className="refresh-btn">
              {canRefreshOrgMembers && (
                <Tooltip
                  title="Refresh Members from GitHub"
                  className="frcc refresh"
                >
                  <span>
                    <IconButton
                      onClick={handleRefreshFromGitHub}
                      disabled={refreshing || loading}
                      color="primary"
                    >
                      {refreshing ? (
                        <CircularProgress size={24} />
                      ) : (
                        <RefreshIcon />
                      )}
                    </IconButton>
                    Fetch from GitHub
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          {seatError && <p className="f-row aic error">⚠️ {seatError}</p>}

          {unifiedSeats.length === 0 ? (
            <div className="empty-state">
              <Typography variant="body2" sx={{ fontStyle: "italic", mb: 2 }}>
                No users found
              </Typography>
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        Role
                        <Tooltip
                          title={
                            <div>
                              <p>
                                <strong>Owner:</strong> Full access to all
                                features and can manage all aspects of the
                                organization.
                              </p>
                              <p>
                                <strong>Admin:</strong> Access to manage
                                repository and user access settings.
                              </p>
                              <p>
                                <strong>User:</strong> Access to Chat feature
                                for repositories with write permissions in
                                Github.
                              </p>
                            </div>
                          }
                          placement="top"
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                        >
                          <IconButton size="small" style={{ padding: "2px" }}>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* show owner first, then user itself admins and then everyone else */}
                  {unifiedSeats
                    .sort((a, b) => {
                      if (a.role === "owner") return -1;
                      if (b.role === "owner") return 1;
                      if (a.userGithubId === userGithubId) return -1;
                      if (b.userGithubId === userGithubId) return 1;
                      if (a.role === "admin") return -1;
                      if (b.role === "admin") return 1;
                      return 0;
                    })
                    .map((seat) => {
                      const seatIdStr = seat.userGithubId.toString();
                      const isPendingAdd = seat.isPendingAddition === true;
                      const isPendingRemove =
                        pendingChanges[seatIdStr]?.type === "remove";
                      const pendingRole = pendingChanges[seatIdStr]?.data.role;

                      return (
                        <tr key={seat.userGithubId}>
                          <td>
                            <div className="f-row aic user-row">
                              <a
                                href={seat.htmlUrl}
                                className="f-row aic username"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {seat.avatarUrl && (
                                  <img
                                    src={seat.avatarUrl}
                                    alt="user avatar"
                                    className="user-avatar"
                                  />
                                )}
                                {/* if removing user, strike through the username  and color red*/}
                                {isPendingRemove ? (
                                  <span className="username-remove">
                                    {seat.username}
                                  </span>
                                ) : isPendingAdd ? (
                                  <span className="username-add">
                                    {seat.username}
                                  </span>
                                ) : (
                                  <span>{seat.username}</span>
                                )}
                              </a>
                              {seat.userGithubId === userGithubId && (
                                <span className="tag owner">You</span>
                              )}
                            </div>
                          </td>
                          <td>
                            {seat.role === "owner" ? (
                              <span className="self-role">Owner</span>
                            ) : userGithubId === seat.userGithubId ||
                              userRole === "admin" ? (
                              <span className="self-role">{seat.role}</span>
                            ) : (
                              <FormControl size="small" sx={{ minWidth: 100 }}>
                                <Select
                                  value={pendingRole || seat.role || "user"}
                                  onChange={(e) =>
                                    handleUserRoleChange(
                                      seatIdStr,
                                      e.target.value
                                    )
                                  }
                                  disabled={isPendingRemove}
                                >
                                  <MenuItem value="user">User</MenuItem>
                                  <MenuItem value="admin">Admin</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </td>
                          <td>
                            {/* disable remove for self-removal, if seat is of owner, or if user is admin and seat is admin . only owners can remove admins*/}
                            {seat.userGithubId !== userGithubId &&
                              seat.role !== "owner" &&
                              (userRole !== "admin" ||
                                seat.role !== "admin") && (
                                <div
                                  className="f-row remove-btn"
                                  tabIndex={0}
                                  onClick={() => removePendingSeat(seat)}
                                >
                                  Remove
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}
          <div className="f-row aife jcsb">
            <button
              className={`save-btn ${
                Object.keys(pendingChanges).length ? "active" : "disabled"
              }`}
              disabled={
                Object.keys(pendingChanges).length === 0 || confirmingChanges
              }
              onClick={confirmChanges}
            >
              {confirmingChanges ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </>
      )}

      <SnackbarAlert snackbar={snackbar} setSnackbar={setSnackbar} />
    </div>
  );
}

export default UserManagement;
