// RepositoryDetail.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import "../components/Dashboard.scss";
import LogoNavbar from "../components/utils/LogoNavbar";
import Loading from "../components/utils/Loading";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Snackbar, Alert } from "@mui/material";
import { updateRepoSettings } from "../components/api/dashboardFunctions";
import TextField from "@mui/material/TextField";
import FpErrorMessage from "../components/utils/FpErrorMessage";
import { useOrgContext } from "../components/utils/OrgContext";

const RepositoryDetail = () => {
  const navigate = useNavigate();
  const { repoId } = useParams();
  const { currentOrg, loadingOrgs } = useOrgContext();
  const [repository, setRepository] = useState(null);

  const [initialAutoReviewEnabled, setInitialAutoReviewEnabled] =
    useState(false);
  const [newAutoReviewEnabled, setNewAutoReviewEnabled] = useState(false);
  const [initialCustomReviewInstructions, setInitialCustomReviewInstructions] =
    useState("");
  const [newCustomReviewInstructions, setNewCustomReviewInstructions] =
    useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [saveMessageColor, setSaveMessageColor] = useState("success");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    if (currentOrg) {
      const foundRepo = currentOrg.repos.find(
        (r) => r.id.toString() === repoId
      );
      if (foundRepo) {
        setRepository(foundRepo);
        setInitialAutoReviewEnabled(foundRepo.autoReviewEnabled);
        setNewAutoReviewEnabled(foundRepo.autoReviewEnabled);
        setInitialCustomReviewInstructions(
          foundRepo.customReviewInstructions ?? ""
        );
        setNewCustomReviewInstructions(
          foundRepo.customReviewInstructions ?? ""
        );
      } else {
        setRepository(null);
        navigate("/repos");
      }
    }
  }, [currentOrg, repoId, navigate]);

  const handleSave = async () => {
    setSaving(true);
    setSaveMessage("");

    // Validate word count before saving
    const wordCount = newCustomReviewInstructions.trim().split(/\s+/).length;
    if (wordCount > 1000) {
      setSaveMessage(
        "Custom instructions exceed 1000 words limit. Please reduce the length."
      );
      setSaveMessageColor("error");
      setSaving(false);
      return;
    }

    try {
      const updates = {};
      if (newAutoReviewEnabled !== initialAutoReviewEnabled) {
        updates.autoReviewEnabled = newAutoReviewEnabled;
      }
      if (newCustomReviewInstructions !== initialCustomReviewInstructions) {
        updates.customReviewInstructions = newCustomReviewInstructions;
      }

      if (Object.keys(updates).length > 0) {
        const response = await updateRepoSettings(repoId, updates);
        if (response.severity === "success") {
          // Update all initial values
          setInitialAutoReviewEnabled(newAutoReviewEnabled);
          setInitialCustomReviewInstructions(newCustomReviewInstructions);
          setSnackbar({
            open: true,
            message: response.message,
            severity: response.severity,
          });
        } else {
          setSnackbar({
            open: true,
            message: response.message,
            severity: response.severity,
          });
        }
      }
      setIsDirty(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          "Error saving settings. Please try again or reach out if the problem persists.",
        severity: "error",
      });
    }
    setSaving(false);
  };

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="repos" />
      {loadingOrgs ? (
        <Loading />
      ) : currentOrg ? (
        <div className="main-outer">
          <div className="f-col aifs main repo-deets">
            <LogoNavbar />
            {repository ? (
              <>
                <div className="frcc route">
                  <Link to="/repos">Repositories</Link>
                  <i className="material-icons icn">navigate_next</i>{" "}
                  {repository.full_name}
                </div>
                <div className="f-row aic jcsb header">
                  <div className="frcc lefty">
                    <a
                      href={repository.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="frcc title"
                    >
                      <h1 className="f-row aifs">
                        {repository.full_name}{" "}
                        <i className="material-icons open-icn">open_in_new</i>
                      </h1>
                    </a>
                    <div
                      className={
                        `f-row aic status-badge ` +
                        (repository.active ? "active" : "inactive")
                      }
                    >
                      {repository.active && (
                        <span className="pulsating-circle"></span>
                      )}
                      {repository.active ? "Active" : "Inactive"}
                    </div>
                  </div>
                  <a
                    href={`https://github.com/settings/installations/${repository.installationId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="save-btn"
                  >
                    Modify Installation
                  </a>
                </div>
                <div className="f-row directive">
                  {repository.active ? (
                    <p>
                      devlo is active on pull requests for this repository.{" "}
                      <br />
                      You can ask questions or request code changes by starting
                      your comments with <span className="code">@devloai</span>
                    </p>
                  ) : (
                    <p>
                      devlo will not perform any actions on this pull request
                    </p>
                  )}
                </div>
                <div className="toggle-section">
                  <h2>Settings</h2>
                  <FormGroup>
                    {/* <h3 className="settings-subtitle">Review Settings</h3> */}
                    <FormControlLabel
                      label={
                        <div>
                          <span>Automatically review new pull requests</span>
                          <div className="note">
                            When enabled, devlo will review new pull requests
                            automatically.
                          </div>
                        </div>
                      }
                      control={
                        <Switch
                          checked={newAutoReviewEnabled}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            setNewAutoReviewEnabled(newValue);
                            setIsDirty(
                              newValue !== initialAutoReviewEnabled ||
                                newCustomReviewInstructions !==
                                  initialCustomReviewInstructions
                            );
                          }}
                          name="auto-review-toggle"
                          color="primary"
                        />
                      }
                    />
                    {newAutoReviewEnabled && (
                      <TextField
                        label="Custom review instructions"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        placeholder="Add custom instructions for devlo when it automatically reviews new PRs. For example: ensure proper error handling, ignore files under /dir"
                        value={newCustomReviewInstructions}
                        onChange={(e) => {
                          const newValue = e.target.value;

                          // Security validations
                          if (newValue.length > 16000) {
                            return; // Prevent input if too long
                          }

                          // Check word count (max 1000 words) while preserving format
                          const words = newValue.match(/\S+/g) || [];
                          if (words.length <= 1000) {
                            setNewCustomReviewInstructions(newValue);
                            setIsDirty(
                              newValue !== initialCustomReviewInstructions ||
                                newAutoReviewEnabled !==
                                  initialAutoReviewEnabled
                            );
                          }
                        }}
                        error={
                          (newCustomReviewInstructions.match(/\S+/g) || [])
                            .length > 1000 ||
                          newCustomReviewInstructions.length > 16000 ||
                          /<script|javascript:|data:/i.test(
                            newCustomReviewInstructions
                          )
                        }
                        helperText={
                          newCustomReviewInstructions.length > 16000
                            ? "Text exceeds maximum allowed length"
                            : `${
                                (
                                  newCustomReviewInstructions.match(/\S+/g) ||
                                  []
                                ).length
                              }/1000 words${
                                (
                                  newCustomReviewInstructions.match(/\S+/g) ||
                                  []
                                ).length > 1000
                                  ? " - Maximum 1000 words allowed"
                                  : ""
                              }`
                        }
                        FormHelperTextProps={{
                          sx: {
                            color:
                              newCustomReviewInstructions.trim().split(/\s+/)
                                .length > 1000
                                ? "error.main"
                                : "text.secondary",
                          },
                        }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                    )}
                  </FormGroup>
                  <button
                    className={`save-btn ${isDirty ? "active" : "disabled"}`}
                    disabled={
                      !isDirty ||
                      saving ||
                      newCustomReviewInstructions.trim().split(/\s+/).length >
                        1000
                    }
                    onClick={handleSave}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                  {saveMessage && (
                    <div className={`message-box ${saveMessageColor}`}>
                      {saveMessage}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <FpErrorMessage message={"Repository not found"} />
            )}
          </div>
        </div>
      ) : (
        <FpErrorMessage message={"Organization not found"} />
      )}
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RepositoryDetail;
