import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useOrgContext } from "./utils/OrgContext";
import { db } from "../firebase-config";
import {
  doc,
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Tooltip,
  ListSubheader,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import StopIcon from "@mui/icons-material/Stop";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import mainLogo from "../assets/devlo.png";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import Loading from "./utils/Loading";
import {
  createChat,
  sendChatMessage,
  listChats,
  deleteChat,
} from "./api/chatFunctions";

import "./Chat.scss";
import { useAuth } from "./utils/AuthContext";
import { Link } from "react-router-dom";
import { getEditableRepos } from "./api/dashboardFunctions";

// Add these suggestions at the top of the file after imports
const CHAT_SUGGESTIONS = [
  "Identify potential vulnerabilities in my codebase",
  "Generate a test coverage report for my codebase",
  "Review and update my README.md",
  "Analyze my code for performance bottlenecks",
];

// Add these constants at the top of the file, after imports
const WORD_COUNT_THRESHOLD = 50000;

// Add this helper function before the Chat component
const countWords = (text) => {
  if (!text) return 0;
  const trimmedContent = text.trim();

  // If there's no whitespace (i.e. a single long "word"), use char-to-word ratio
  if (!/\s/.test(trimmedContent)) {
    const avgWordLength = 5;
    return Math.ceil(trimmedContent.length / avgWordLength);
  }

  // Otherwise, split on whitespace
  return trimmedContent.split(/\s+/).length;
};

// Add this new function at the top level, after existing imports
const isMobileView = () => window.innerWidth <= 768;

/* -------------------------------
   MAIN CHAT COMPONENT
--------------------------------- */
function Chat() {
  const { authUser, authLoading, firebaseUser, signOut } = useAuth();
  const { orgs, currentOrg, loadingOrgs } = useOrgContext();
  const [message, setMessage] = useState("");
  const [selectedRepo, setSelectedRepo] = useState("");
  const [selectedRepoName, setSelectedRepoName] = useState("");
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingChats, setLoadingChats] = useState(true);
  const [showGhostLoading, setShowGhostLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobileView());
  const sidebarRef = useRef(null);
  const [chatInputMessageError, setChatInputMessageError] = useState("");
  const [sentMessageError, setSentMessageError] = useState("");
  const [editableRepos, setEditableRepos] = useState([]);
  // Get 3 random suggestions when selectedRepo changes
  const randomSuggestions = useMemo(() => {
    const shuffled = [...CHAT_SUGGESTIONS].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  // Focus input when chat or repo is selected
  useEffect(() => {
    if (
      inputRef.current &&
      ((currentChat && !loading) || (!currentChat && selectedRepo))
    ) {
      inputRef.current.focus();
    }
  }, [currentChat, selectedRepo, loading]);

  // Scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Add this helper function to get all repos grouped by org
  const getGroupedRepos = useMemo(() => {
    let grouped_repos = [];
    const listedOrgs = new Set();
    if (orgs) {
      for (const org of orgs) {
        if (org.repos.length > 0) {
          grouped_repos.push({
            orgName: org.org.name,
            orgId: org.id,
            repos: org.repos || [],
          });
          listedOrgs.add(org.id);
        }
      }
    }
    if (editableRepos) {
      for (const repo of editableRepos) {
        if (!listedOrgs.has(repo.orgId)) {
          grouped_repos.push({
            orgName: repo.orgName,
            orgId: repo.orgId,
            repos: repo.repos,
          });
          listedOrgs.add(repo.orgId);
        }
      }
    }
    return grouped_repos;
  }, [orgs, editableRepos]);

  // Update the useEffect to use first repo from all orgs
  useEffect(() => {
    if (orgs) {
      setLoadingChats(true);
      // Set the first repo as default if available and no repo is selected
      const firstOrgWithRepos = orgs.find((org) => org.repos?.length > 0);
      if (firstOrgWithRepos?.repos[0]) {
        setSelectedRepo(firstOrgWithRepos.repos[0].id);
        setSelectedRepoName(firstOrgWithRepos.repos[0].name);
      }

      listChats()
        .then((chatList) => {
          setChats(chatList);
        })
        .catch((error) => {
          alert("Error loading chats");
        })
        .finally(() => setLoadingChats(false));
      getEditableRepos()
        .then((repos) => {
          setEditableRepos(repos.repos);
        })
        .catch((error) => {
          console.error("Error loading editable repos");
        });
    }
  }, [orgs]);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Real-time listeners for current chat
  useEffect(() => {
    if (!currentChat) return;
    if (currentChat.optimistic) return;

    const unsubscribeChat = onSnapshot(
      doc(
        db,
        "repositories",
        currentChat.repoId.toString(),
        "chats",
        currentChat.id.toString()
      ),
      (docSnap) => {
        const chatData = docSnap.data();
        if (chatData) {
          setCurrentChat((prev) => ({ ...prev, ...chatData }));
          if (chatData.repoId) {
            setSelectedRepo(chatData.repoId);
            setSelectedRepoName(chatData.repoName);
          }
        }
      }
    );

    const unsubscribeMessages = onSnapshot(
      query(
        collection(
          db,
          "repositories",
          currentChat.repoId.toString(),
          "chats",
          currentChat.id.toString(),
          "messages"
        ),
        orderBy("createdAt", "asc")
      ),
      (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (
          updatedMessages.length > 0 &&
          updatedMessages[updatedMessages.length - 1].role === "assistant"
        ) {
          setShowGhostLoading(false);
        }
        setMessages(updatedMessages);
        scrollToBottom();
      }
    );

    return () => {
      unsubscribeChat();
      unsubscribeMessages();
    };
    // eslint-disable-next-line
  }, [currentChat?.uniqueId]);

  const handleSuggestionClick = useCallback((suggestion) => {
    setMessage(suggestion);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Add this function to validate message length
  const validateMessage = (text) => {
    const wordCount = countWords(text);
    if (wordCount > WORD_COUNT_THRESHOLD) {
      setChatInputMessageError(
        `Message is too long. Please reduce the length of your message.`
      );
      return false;
    }
    setChatInputMessageError("");
    return true;
  };

  // Send a user message
  const handleSendMessage = async () => {
    if (!message.trim()) return;

    if (!validateMessage(message)) {
      return;
    }

    if (currentChat?.isProcessing) {
      alert("Please wait for the current task to complete.");
      return;
    }

    const isNewChat = !currentChat;
    if (isNewChat && !selectedRepo) {
      alert("Please select a repository before starting a chat.");
      return;
    }

    const newMessage = {
      content: message,
      role: "user",
    };

    const tempId = `temp-${Date.now()}`;
    // Optimistic UI
    const optimisticMessage = {
      ...newMessage,
      id: tempId,
      createdAt: new Date(),
      status: "sending",
      repoId: selectedRepo,
    };

    // For new chats, create an optimistic chat first
    if (isNewChat) {
      const optimisticChat = {
        id: tempId,
        uniqueId: tempId,
        optimistic: true,
        title: message.substring(0, 30) + (message.length > 30 ? "..." : ""),
        createdAt: new Date(),
        repoId: selectedRepo,
        userId: firebaseUser.uid,
        repoName: selectedRepoName,
      };
      setChats((prev) => [optimisticChat, ...prev]);
      setCurrentChat(optimisticChat);
      setMessages([optimisticMessage]); // Start with just the optimistic message
    } else {
      setMessages((prev) => [...prev, optimisticMessage]);
    }

    setMessage("");
    setLoading(true);
    setShowGhostLoading(true);

    try {
      let response;
      if (isNewChat) {
        response = await createChat(selectedRepo, message);

        const newChat = {
          id: response.chatId,
          uniqueId: response.uniqueId,
          title: message.substring(0, 30) + (message.length > 30 ? "..." : ""),
          createdAt: new Date(),
          repoId: response.repoId,
          userId: firebaseUser.uid,
          repoName: response.repoName,
        };

        setChats((prev) => [
          newChat,
          ...prev.filter((chat) => !chat.optimistic),
        ]);
        setCurrentChat(newChat);

        // The messages will be updated via the snapshot listener
      } else {
        response = await sendChatMessage(
          currentChat.repoId,
          currentChat.id,
          message
        );
      }
    } catch (error) {
      const fallbackError = isNewChat
        ? "Error creating chat"
        : "Error sending message";
      setSentMessageError(error.message || fallbackError);
      setShowGhostLoading(false);
      setMessages((prev) =>
        prev.map((m) =>
          m.id === optimisticMessage.id ? { ...m, status: "error" } : m
        )
      );
    } finally {
      setLoading(false);
    }
  };

  // Start a new chat
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);
    // Close sidebar on mobile when creating new chat
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
  };

  // Select an existing chat from sidebar
  const handleSelectChat = (chat) => {
    setCurrentChat(chat);
    // Close sidebar on mobile when selecting a chat
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
  };

  // Format each message with markdown, preContent, and tool statuses
  const formatMessage = (message) => {
    const messageContent =
      typeof message.content === "string" ? message.content : "";
    const preContent =
      typeof message.preContent === "string" ? message.preContent : "";

    return (
      <Box className="message-content">
        <div className="message-text">
          {message.role === "user" ? (
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                fontFamily: "inherit",
                margin: 0,
              }}
            >
              {messageContent}
            </pre>
          ) : message.isToolProcessing || false ? (
            <>
              <div className="message-text">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {preContent}
                </ReactMarkdown>
              </div>
              <Box className="processing-indicator">
                <div className="progress-container">
                  <CircularProgress size={20} />
                  <StopIcon
                    className="stop-icon"
                    onClick={() =>
                      handleCancel(
                        message.id,
                        currentChat?.id,
                        currentChat?.repoId
                      )
                    }
                  />
                </div>
                {message.toolStatus}
              </Box>
            </>
          ) : messageContent ? (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  const language = match ? match[1] : "";
                  const content = String(children).replace(/\n$/, "");

                  if (inline || !language) {
                    return (
                      <code className={className} {...props}>
                        {content}
                      </code>
                    );
                  }

                  return (
                    <SyntaxHighlighter
                      style={oneDark}
                      language={language}
                      PreTag="div"
                      customStyle={{
                        margin: "0",
                        borderRadius: "6px",
                        background: "#1e1e1e",
                      }}
                    >
                      {content}
                    </SyntaxHighlighter>
                  );
                },
              }}
            >
              {messageContent}
            </ReactMarkdown>
          ) : (
            <div className="message-text">
              Could not retrieve message content
            </div>
          )}
        </div>
        {/* Status indicators for user messages */}
        {message.status === "error" && (
          <Typography variant="caption" color="error">
            {sentMessageError}
          </Typography>
        )}
      </Box>
    );
  };

  // Inside the Chat component, add this function before the formatMessage function
  const handleCancel = async (messageId, chatId, repoId) => {
    if (!messageId || !chatId || !repoId) return;

    try {
      const messageRef = doc(
        db,
        "repositories",
        repoId.toString(),
        "chats",
        chatId.toString(),
        "messages",
        messageId
      );
      await updateDoc(messageRef, {
        isToolProcessing: false,
        isToolCanceled: true,
        toolStatus: "Canceled.",
        content: "Canceled by user.",
      });

      const chatRef = doc(
        db,
        "repositories",
        repoId.toString(),
        "chats",
        chatId.toString()
      );
      await updateDoc(chatRef, {
        isProcessing: false,
        lastProcessedAt: Timestamp.now(),
      });

      setCurrentChat((prev) => ({
        ...prev,
        isProcessing: false,
        lastProcessedAt: Timestamp.now(),
      }));
    } catch (error) {
      setSentMessageError("Error canceling task:", error);
    }
  };

  // Update the getRelativeTime function
  const getRelativeTime = (timestamp) => {
    if (!timestamp) return "";

    const now = new Date();
    // Handle both Firestore timestamp and ISO string
    const date = timestamp.seconds
      ? new Date(timestamp.seconds * 1000)
      : new Date(timestamp);

    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "just now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)}h ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)}d ago`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w ago`;
    return "over a month ago";
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Add this useEffect for handling clicks outside the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Only handle outside clicks on mobile
      if (window.innerWidth > 768) return;

      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add this function inside the Chat component before the return statement
  const handleDeleteChat = async (e, chat) => {
    e.stopPropagation(); // Prevent chat selection when clicking delete

    if (window.confirm("Are you sure you want to delete this chat?")) {
      try {
        // If the deleted chat was selected, clear the current chat first to stop listeners
        if (currentChat?.uniqueId === chat.uniqueId) {
          setCurrentChat(null);
          setMessages([]);
        }

        // Delete the chat after clearing listeners
        await deleteChat(chat.repoId, chat.id);

        // Remove chat from local state
        setChats((prevChats) =>
          prevChats.filter((c) => c.uniqueId !== chat.uniqueId)
        );
      } catch (error) {
        alert("Failed to delete chat");
      }
    }
  };

  // Add this useEffect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(!isMobileView());
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="chat-container">
      {/* Sidebar Toggle Button */}
      <button
        className="sidebar-toggle"
        onClick={toggleSidebar}
        style={{ visibility: isSidebarOpen ? "hidden" : "visible" }}
      >
        <i className="material-icons">menu</i>
      </button>

      {/* LEFT SIDEBAR - add ref */}
      <div
        ref={sidebarRef}
        className={`chat-sidebar ${!isSidebarOpen ? "collapsed" : ""}`}
      >
        <div className="sidebar-header">
          <Link className="navbar-brand" to="/repos">
            <img src={mainLogo} alt="Logo" className="logo" />
            <span>devlo</span>
          </Link>
          <button
            className="in-sidebar-toggle"
            onClick={toggleSidebar}
            style={{ visibility: isSidebarOpen ? "visible" : "hidden" }}
          >
            <i className="material-icons">chevron_left</i>
          </button>
        </div>

        <div className="sidebar-content">
          <Button
            variant="contained"
            className="new-chat-btn"
            onClick={handleNewChat}
            startIcon={<AddIcon />}
          >
            New Chat
          </Button>

          <Typography className="sidebar-title">Recent Chats</Typography>
          {loadingChats ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : chats.length === 0 ? (
            <Typography variant="body2" color="text.secondary">
              No recent chats
            </Typography>
          ) : (
            <div className="chat-list">
              {chats.map((chat) => (
                <div
                  key={chat.uniqueId}
                  className={`chat-item ${
                    currentChat?.uniqueId === chat.uniqueId ? "active" : ""
                  }`}
                  onClick={() => handleSelectChat(chat)}
                >
                  <div className="chat-item-content">
                    <Typography variant="body1" noWrap>
                      {chat.title}
                    </Typography>
                    <div className="f-row aife">
                      <div className="f-col aifs">
                        <div className="repo-tag">Repo: {chat.repoName}</div>
                        <Typography variant="caption" color="text.secondary">
                          Updated{" "}
                          {getRelativeTime(chat.updatedAt || chat.createdAt)}
                        </Typography>
                      </div>
                      <div
                        className="delete-icon"
                        onClick={(e) => handleDeleteChat(e, chat)}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="f-col aifs sidebar-footer">
          {orgs.length > 0 ? (
            <>
              <Link to="/repos" className="f-row aic">
                <i className="material-icons">code</i> Repositories
              </Link>
              <Link to="/settings" className="f-row aic">
                <i className="material-icons">settings</i> Settings
              </Link>
            </>
          ) : (
            <Link to="/repos" className="f-row aic">
              <i className="material-icons">arrow_back</i> Dashboard
            </Link>
          )}
          <div className="f-row aic jcsb user-sec-cnt">
            <div className="frcc user-sec">
              {!authLoading && authUser && authUser.avatarUrl ? (
                <>
                  <img
                    src={authUser.avatarUrl}
                    alt="User Avatar"
                    className="user-avatar navbar-avatar"
                  />
                  <h4>{authUser.name}</h4>
                </>
              ) : (
                <CircularProgress />
              )}
            </div>

            <button onClick={handleSignOut}>
              <i className="material-icons">logout</i>
            </button>
          </div>
        </div>
      </div>

      {/* MAIN CHAT AREA */}
      <div className="chat-main">
        {/* MESSAGES SCROLL AREA */}
        {loadingOrgs ? (
          <Loading />
        ) : currentChat ? (
          <>
            <Box className="chat-header">
              <Typography variant="h5" className="chat-title">
                {currentChat.title}
              </Typography>
              <Typography className="repo-tag">
                Repo: {currentChat.repoName}
              </Typography>
            </Box>
            <Divider />

            <div className="chat-messages">
              {messages.length === 0 ? (
                <Box className="empty-chat">
                  <Typography variant="h6" color="text.secondary">
                    Start a conversation with @devloai
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ask questions about your code, get help with tasks, or
                    discuss your projects.
                  </Typography>
                </Box>
              ) : (
                <>
                  {messages.map((msg) => (
                    <Box
                      key={msg.id}
                      className={`message ${
                        msg.role === "user"
                          ? "user-message"
                          : "assistant-message"
                      }`}
                      sx={{ display: "inline-block" }}
                    >
                      {formatMessage(msg)}
                      {msg.role === "assistant" && (
                        <div className="frcc message-avatar">
                          <img src={mainLogo} alt="Assistant Avatar" />
                        </div>
                      )}
                    </Box>
                  ))}
                  {showGhostLoading && (
                    <Box className="ghost-loading">
                      <div className="frcc message-avatar">
                        <img src={mainLogo} alt="Assistant Avatar" />
                      </div>
                      <div className="ghost-bars">
                        <div className="ghost-bar"></div>
                        <div className="ghost-bar"></div>
                        <div className="ghost-bar"></div>
                      </div>
                    </Box>
                  )}
                </>
              )}
              <div ref={messagesEndRef} />
            </div>
          </>
        ) : (
          /* No currentChat: show center content for repo selection */
          <div className="centered-content">
            <Typography variant="h4" className="hello-text pb-grd">
              {!authLoading && authUser.name
                ? `Hello, ${authUser.name.split(" ")[0]}`
                : "Hello there"}
            </Typography>
            {currentOrg?.repos?.length ? (
              <>
                <p>Please select a repository to start assigning tasks</p>
                <FormControl className="repo-dropdown">
                  <InputLabel>Repository</InputLabel>
                  <Select
                    value={selectedRepo}
                    onChange={(e) => {
                      setSelectedRepo(e.target.value);
                      // Find the selected repo's name from the grouped repos
                      const selectedRepoObj = getGroupedRepos
                        .flatMap((org) => org.repos)
                        .find((repo) => repo.id === e.target.value);
                      setSelectedRepoName(selectedRepoObj?.name || "");
                    }}
                    label="Repository"
                  >
                    {getGroupedRepos.map((org) => [
                      <ListSubheader key={org.orgId}>
                        {org.orgName}
                      </ListSubheader>,
                      org.repos.map((repo) => (
                        <MenuItem key={repo.id} value={repo.id} sx={{ pl: 4 }}>
                          {repo.name}
                        </MenuItem>
                      )),
                    ])}
                  </Select>
                </FormControl>
                <p className="ghb">
                  <br />
                  You can also assign tasks directly through Github by tagging
                  @devloai (see&nbsp;
                  <a
                    href="https://devlo.ai/guide"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide
                  </a>
                  )
                </p>
              </>
            ) : (
              <>
                <p>Connect your repositories to start assigning tasks</p>
                <a
                  href="https://github.com/apps/devloai/installations/select_target"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc cta-btn"
                >
                  {" "}
                  Connect repositories
                </a>
              </>
            )}
          </div>
        )}

        {/* Update the suggestions rendering */}
        {!currentChat && (
          <div className="frcc chat-suggestions">
            {randomSuggestions.map((suggestion, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  className="suggestion-button"
                  onClick={() => handleSuggestionClick(suggestion)}
                  disabled={!selectedRepo}
                >
                  {suggestion}
                </Button>
              );
            })}
          </div>
        )}

        {/* CHAT INPUT (ALWAYS VISIBLE) */}
        <Box
          className={`chat-input ${
            loading || currentChat?.isProcessing || !selectedRepo
              ? "disabled"
              : ""
          }`}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder={
              selectedRepo
                ? "What can I help you with?"
                : "Please select a repository to start chatting"
            }
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              validateMessage(e.target.value);
            }}
            onKeyPress={(e) =>
              e.key === "Enter" && !e.shiftKey && handleSendMessage()
            }
            disabled={loading || currentChat?.isProcessing || !selectedRepo}
            multiline
            maxRows={4}
            className="message-input"
            error={!!chatInputMessageError}
            helperText={chatInputMessageError}
            inputRef={inputRef}
          />
          <Tooltip
            title={
              !selectedRepo
                ? "Please select a repository"
                : currentChat?.isProcessing
                ? "Please wait for the current task to complete"
                : chatInputMessageError
                ? chatInputMessageError
                : ""
            }
            placement="top"
          >
            <span>
              <Button
                variant="text"
                className="send-button"
                endIcon={<SendIcon />}
                onClick={handleSendMessage}
                disabled={
                  !message.trim() ||
                  loading ||
                  currentChat?.isProcessing ||
                  !selectedRepo ||
                  !!chatInputMessageError
                }
              ></Button>
            </span>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
}

export default Chat;
