// src/components/api/chatFunctions.js
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase-config";

// Create a new chat
export async function createChat(repoId, initialMessage) {
  const fn = httpsCallable(functions, "createChat");
  const response = await fn({ repoId, initialMessage });
  return response.data;
}

// Send a chat message to an existing chat
export async function sendChatMessage(repoId, chatId, content) {
  const fn = httpsCallable(functions, "sendChatMessage");
  const response = await fn({ repoId, chatId, content });
  return response.data;
}

// List user's chats
export async function listChats() {
  const fn = httpsCallable(functions, "listChats");
  const response = await fn();
  return response.data.chats;
}

// Delete a chat
export async function deleteChat(repoId, chatId) {
  const fn = httpsCallable(functions, "deleteChat");
  const response = await fn({ repoId, chatId });
  return response.data;
}
