// src/api/myFunctions.js
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase-config";

export async function getDashboardData() {
  const getDashboardData = httpsCallable(functions, "getDashboardData");
  const response = await getDashboardData();
  // response.data => { orgs: [...] }
  return response.data.orgs;
}

export async function updateRepoSettings(repoId, settings) {
  const fn = httpsCallable(functions, "updateRepoSettings");
  const response = await fn({ repoId, settings });
  return response.data;
}

export async function updateAutoAssignSeats(orgId, autoAssignSeats) {
  const fn = httpsCallable(functions, "updateAutoAssignSeats");
  const response = await fn({ orgId, autoAssignSeats });
  // response.data => { org: {...}, seats: [...] }
  return response.data;
}

export async function createCheckoutSession(
  orgId,
  amount,
  successUrl,
  cancelUrl
) {
  const fn = httpsCallable(functions, "createCheckoutSession");
  const response = await fn({ orgId, amount, successUrl, cancelUrl });
  return response.data;
}

export async function createPortalSession(orgId, returnUrl) {
  const fn = httpsCallable(functions, "createPortalSession");
  const response = await fn({ orgId, returnUrl });
  return response.data;
}

export async function batchSeatsUpdate(orgId, changes) {
  const fn = httpsCallable(functions, "batchSeatsUpdate");
  const response = await fn({ orgId, changes });
  return response.data;
}

export async function getOrgSeats(orgId) {
  const fn = httpsCallable(functions, "getOrgSeats");
  const response = await fn({ orgId });
  return response.data;
}

export async function refreshOrgMembers(orgId) {
  const fn = httpsCallable(functions, "refreshOrgMembers");
  const response = await fn({ orgId });
  return response.data;
}

export async function getEditableRepos() {
  const fn = httpsCallable(functions, "getEditableRepos");
  const response = await fn();
  return response.data;
}
