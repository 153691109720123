import React, { useState } from "react";
import { useOrgContext } from "../components/utils/OrgContext";
import Sidebar from "../components/Sidebar";
import UserManagement from "../components/UserManagement";
import { Box, Tab, Tabs } from "@mui/material";
import "../components/Dashboard.scss";
import Loading from "../components/utils/Loading";
import FpErrorMessage from "../components/utils/FpErrorMessage";
import SubscriptionSettings from "./SubscriptionSettings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-panel">{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

function Settings() {
  const { currentOrg, userRole, loadingOrgs } = useOrgContext();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!loadingOrgs && userRole !== "admin" && userRole !== "owner") {
    return (
      <FpErrorMessage message={"You are not authorized to access this page"} />
    );
  }

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="settings" />
      {loadingOrgs ? (
        <Loading />
      ) : userRole !== "admin" && userRole !== "owner" ? (
        <FpErrorMessage
          message={"You are not authorized to access this page"}
          hideConnectGithub={true}
        />
      ) : currentOrg ? (
        <div className="main-outer">
          <div className="main">
            <h1>Settings</h1>

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="settings tabs"
                >
                  <Tab label="Subscription" {...a11yProps(0)} />
                  <Tab label="User Management" {...a11yProps(0)} />
                  {/* Add more tabs here as needed */}
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <SubscriptionSettings />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <UserManagement orgId={currentOrg.id} userRole={userRole} />
              </TabPanel>
              {/* Add more TabPanels here as needed */}
            </Box>
          </div>
        </div>
      ) : (
        <FpErrorMessage message={"No Organizations found"} />
      )}
    </div>
  );
}

export default Settings;
