import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "../components/Dashboard.scss";
import "./SubscriptionSettings.scss";
import Loading from "../components/utils/Loading";
import { loadStripe } from "@stripe/stripe-js";
import {
  createCheckoutSession,
  createPortalSession,
} from "../components/api/dashboardFunctions";
import { useOrgContext } from "../components/utils/OrgContext";
import FpErrorMessage from "../components/utils/FpErrorMessage";
import SnackbarAlert from "../components/SnackbarAlert";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SubscriptionSettings = () => {
  const { currentOrg, loadingOrgs } = useOrgContext();
  const [creditAmount, setCreditAmount] = useState(10); // Default to $10 minimum
  const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] =
    useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCustomCheckout = async () => {
    if (creditAmount < 10) {
      setSnackbar({
        open: true,
        message: "Minimum purchase is $10.",
        severity: "error",
      });
      return;
    }
    setIsPurchaseLoading(true); // Start loading spinner

    try {
      const { sessionId } = await createCheckoutSession(
        currentOrg.id,
        creditAmount,
        `${window.location.origin}/org/${currentOrg.id}?success=true`,
        `${window.location.origin}/org/${currentOrg.id}?canceled=true`
      );

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to start checkout process",
        severity: "error",
      });
      setIsPurchaseLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    setIsManageSubscriptionLoading(true); // Start loading spinner
    try {
      const { url } = await createPortalSession(
        currentOrg.id,
        `${window.location.origin}/org/${currentOrg.id}`
      );

      window.location.href = url;
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to access billing portal",
        severity: "error",
      });
      setIsManageSubscriptionLoading(false);
    }
  };
  const subscriptionStatusDisplayNames = {
    active: "Active",
    trialing: "Trialing",
    past_due: "Past Due",
    canceled: "Canceled",
    incomplete: "Incomplete",
    incomplete_expired: "Incomplete (Expired)",
    unpaid: "Unpaid",
    paused: "Paused",
  };

  const planDisplayNames = {
    startup_monthly: "Startup",
    pro_monthly: "Pro",
    smb_monthly: "SMB",
    free: "Free",
  };

  const getDisplayPlan = (plan) => planDisplayNames[plan] || "N/A";

  const getDisplaySubscriptionStatus = (status) =>
    subscriptionStatusDisplayNames[status] || "Unknown";

  const isSubscriptionActive = (status) =>
    status !== "paused" && status !== "canceled";

  return (
    <>
      {loadingOrgs ? (
        <Loading />
      ) : currentOrg ? (
        <div className="f-col aifs sub-container">
          {!currentOrg.org.active && (
            <div className="f-col aifs reconnect-org">
              <p>
                {" "}
                Your organization is not active. Please reconnect to continue.
              </p>
              <a
                href="https://github.com/apps/devloai/installations/select_target"
                target="_blank"
                rel="noopener noreferrer"
                className="frcc cta-btn"
              >
                <i className="material-icons">add</i> Reconnect Organization
              </a>
            </div>
          )}

          {currentOrg.org.active && (
            <>
              <div className="f-col aifs subscription-section">
                <div className="f-col deets">
                  <div className="f-col sub-deet">
                    <h2 className="f-row aicsubscription-title">
                      Subscription
                    </h2>
                    <p>
                      Plan:&nbsp;
                      {getDisplayPlan(currentOrg.org.plan)}
                    </p>
                    <div className="f-col base-sec">
                      <p>
                        Usage:&nbsp;
                        <span className="bold">
                          {Math.max(currentOrg.org.monthlyCreditsUsed || 0, 0)}{" "}
                          / {currentOrg.org.monthlyCredits || 0}
                        </span>
                      </p>
                      <div className="credit-usage-bar">
                        <div
                          className={`usage-bar-fill ${
                            currentOrg.org.monthlyCreditsUsed >=
                            currentOrg.org.monthlyCredits
                              ? "danger"
                              : currentOrg.org.monthlyCreditsUsed >=
                                currentOrg.org.monthlyCredits * 0.9
                              ? "warning"
                              : "success"
                          }`}
                          style={{
                            width: `${
                              Math.max(
                                0,
                                Math.min(
                                  (currentOrg.org.monthlyCreditsUsed /
                                    currentOrg.org.monthlyCredits) *
                                    100,
                                  100
                                )
                              ) || 0
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <p>
                      Status:&nbsp;
                      {getDisplaySubscriptionStatus(
                        currentOrg.org.subscriptionStatus
                      )}
                    </p>
                    {isSubscriptionActive(
                      currentOrg.org.subscriptionStatus
                    ) && (
                      <p>
                        {currentOrg.org.cancelAtPeriodEnd ? "Ends" : "Renews"}
                        :&nbsp;
                        {currentOrg.org.currentPeriodEnd
                          ? new Date(
                              currentOrg.org.currentPeriodEnd.seconds * 1000
                            ).toLocaleDateString()
                          : "N/A"}
                      </p>
                    )}

                    <p>
                      <span>Payment setup:&nbsp;</span>
                      {!currentOrg.org.hasPaymentMethod ? (
                        <span className="bold no-payment">
                          ⚠️ No payment method detected
                        </span>
                      ) : (
                        <span className="bold payment-detected">Done</span>
                      )}
                    </p>
                    <div className="f-col manage-sub">
                      <button
                        className="frcc save-btn manage-subscription-btn"
                        onClick={handleManageSubscription}
                        disabled={isManageSubscriptionLoading}
                      >
                        Manage subscription
                        {isManageSubscriptionLoading && (
                          <CircularProgress
                            style={{ width: 10, height: 10, color: "white" }}
                          />
                        )}
                      </button>
                      <p className="note">
                        Click to change plans, manage billing & subscription.
                        <br />
                        Note: A payment method needs to attached for upgrading
                        plans.
                      </p>
                    </div>
                  </div>

                  <div className="f-col credits-sec">
                    <h2>
                      Additional credits
                      <p className="f-col note purchased-info">
                        Top-up credits with one-time purchase. Base credits are
                        used up first. Purchased credits never expire and roll
                        over to the next month.
                        <br />
                        <Link
                          to="/faq#what-are-devlo-credits"
                          className="credits-link"
                        >
                          See how credits work
                        </Link>
                      </p>
                    </h2>
                    <p className="rem-cred">
                      <span className="bold">
                        Credits remaining:&nbsp;
                        {currentOrg.org.purchasedCredits || 0}
                      </span>
                      {currentOrg.org.purchasedCredits < 0 && (
                        <span className="note">
                          &nbsp;(negative balance due to overflow from usage)
                        </span>
                      )}
                    </p>
                    <div className="f-col buy-credits">
                      <div className="f-row pur-mn">
                        <div className="input-wrapper">
                          <span className="dollar-sign">$</span>
                          <input
                            type="text"
                            value={creditAmount}
                            placeholder="Enter amount"
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Allow only numbers
                              setCreditAmount(value);
                            }}
                          />
                        </div>
                        <button
                          className="frcc save-btn buy-more-btn"
                          onClick={handleCustomCheckout}
                          disabled={
                            isPurchaseLoading ||
                            !creditAmount ||
                            Number(creditAmount) < 10
                          }
                        >
                          Purchase
                          {isPurchaseLoading && (
                            <CircularProgress
                              style={{
                                width: 10,
                                height: 10,
                                color: "white",
                              }}
                            />
                          )}
                        </button>
                      </div>
                      <div className="pur-ft">
                        {creditAmount && Number(creditAmount) < 10 && (
                          <p className="error-message">
                            Minimum purchase is $10.
                          </p>
                        )}
                        <p className="note purchase-info">
                          $1 = 100 credits. Minimum purchase is $10.
                        </p>
                      </div>
                    </div>
                    {/* Error Message */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <FpErrorMessage message={"No Organizations found"} />
      )}
      <SnackbarAlert snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
};

export default SubscriptionSettings;
