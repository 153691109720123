import React from "react";
import "./Dashboard.scss";
import "./Repositories.scss";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Loading from "./utils/Loading";
import LogoNavbar from "./utils/LogoNavbar";
import { useOrgContext } from "./utils/OrgContext";
import FpErrorMessage from "./utils/FpErrorMessage";

const Repositories = () => {
  const { currentOrg, loadingOrgs } = useOrgContext();

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="repos" />
      {loadingOrgs ? (
        <Loading />
      ) : currentOrg ? (
        <div className="main-outer">
          <div className="f-col aifs main">
            <LogoNavbar />
            <div className="f-row aic jcsb header">
              <h1>Repositories</h1>
              <a
                href="https://github.com/apps/devloai/installations/select_target"
                target="_blank"
                rel="noopener noreferrer"
                className="frcc save-btn"
              >
                <i className="material-icons">add</i> Add Repositories
              </a>
            </div>
            {currentOrg.repos.length ? (
              <>
                <p>Here is a list of repositories you have access to.</p>
                <div className="f-col main-container repo-container">
                  <div className="f-row entities">
                    {currentOrg.repos.map((repo) => (
                      <Link
                        key={repo.id}
                        to={`/repo/${repo.id}`}
                        className="f-col jcsb entity"
                      >
                        <h3>{repo.name}</h3>
                        <div
                          className={
                            `f-row aic status-badge ` +
                            (repo.active ? "active" : "inactive")
                          }
                        >
                          {repo.active && (
                            <span className="pulsating-circle"></span>
                          )}
                          {repo.active ? "Active" : "Inactive"}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <span>
                You have not connected any repositories yet. <br />
                <br />
                Note that you can only add and view repositories for an
                organization you are an admin of.
              </span>
            )}
          </div>
        </div>
      ) : (
        <FpErrorMessage message={"No Organizations found"} />
      )}
    </div>
  );
};

export default Repositories;
